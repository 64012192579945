@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .font-family-abril {
    font-family: Abril Fatface,serif;
  }

  .contactInput {
    @apply border border-gray-300 rounded py-2 px-3 transition-all
    focus:border-black
    hover:border-black;
  }

  ul {
    @apply list-disc px-5
  }

  p, ul {
    @apply my-3
  }
  
}

.fancybox__backdrop {
  background-color: white !important;
}


.fancybox__toolbar {
  background: transparent !important;
}

.fancybox__counter, .carousel__button {
  color: #0d1e2d!important;
}